import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import getAllPosts from "../../core/interfaces/posts";

export default function PostsBlock(props: {
  color: string;
  colorDark: string;
  preColor: string;
  preColorDark: string;
}): React.ReactElement {
  const { color, colorDark, preColor, preColorDark } = props;

  const posts = getAllPosts();

  if (posts.length == 0) {
    return null;
  }

  return (
    <section className={`pb-40 relative bg-${color} dark:bg-${colorDark}`}>
      <div
        className={`-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20 bg-${preColor} dark:bg-${preColorDark}`}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-${color} dark:text-${colorDark} fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto">
        <div className="lg:-mt-20 lg:mr-30">
          <h2 className="font-semibold text-5xl text-center lg:text-right mb-12 text-gray-700 dark:text-gray-200">
            Latest Posts
          </h2>
        </div>

        <div className="flex flex-wrap items-center">
          {posts.map((post, post_ix) => (
            <div
              key={post_ix}
              className="w-10/12 md:w-6/12 lg:w-4/12 px-12 py-12 md:px-4 md:py-4 mr-auto ml-auto hover:scale-105 hover:brightness-110 transition-all cursor-pointer"
            >
              <Link to={post.fields.slug}>
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blue-800 hover:shadow-xl transition-all">
                  {!!post.frontmatter.featured_image && (
                    <GatsbyImage
                      image={getImage(post.frontmatter.featured_image)}
                      alt={post.frontmatter.title}
                      className="w-full align-middle rounded-t-lg h-200-px object-cover"
                    />
                  )}
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-blue-800 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">{post.frontmatter.title}</h4>

                    <p className="text-md font-light mt-3 text-white">{post.excerpt}</p>

                    <p className="text-md font-light mt-3 text-white text-right">
                      {new Date(post.frontmatter.date).toLocaleDateString()}
                    </p>
                  </blockquote>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
