import React from "react";
import Layout from "./components/layout";
import PostsBlock from "./home/posts";
import ProfileBlock from "./home/profile";
import PublicationsBlock from "./home/publications";

export default function Home(): React.ReactElement {
  return (
    <Layout>
      <ProfileBlock color="gray-100" colorDark="gray-800" />
      <PublicationsBlock
        preColor="gray-100"
        preColorDark="gray-800"
        color="gray-200"
        colorDark="gray-700"
      />
      <PostsBlock
        preColor="gray-200"
        preColorDark="gray-700"
        color="gray-300"
        colorDark="gray-600"
      />
    </Layout>
  );
}

// bg-gray-100 dark:bg-gray-800 text-gray-100 dark:text-gray-800
// bg-gray-200 dark:bg-gray-700 text-gray-200 dark:text-gray-700
// bg-gray-300 dark:bg-gray-600 text-gray-300 dark:text-gray-600
