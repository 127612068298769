import { graphql, useStaticQuery } from "gatsby";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";

export interface ImageNode {
  src: FileNode;
  name: string;
  alt: string;
  caption: string;
  credit: string;
}

export interface FrontMatter {
  title: string;
  subtitle: string;
  date: string;
  featured_image: FileNode;
  images: ImageNode[];
}

export interface Heading {
  url: string;
  title: string;
  items?: Heading[];
}

export interface TableOfContents {
  items: Heading[];
}

export interface Post {
  frontmatter: FrontMatter;
  fields: {
    slug: string;
  };
  excerpt: string;
  tableOfContents?: TableOfContents;
  body: string;
}

export default function getAllPosts(): Post[] {
  const { allMdx } = useStaticQuery(graphql`
    query GetAllPosts {
      allMdx(filter: { fields: { slug: { ne: null } } }) {
        edges {
          node {
            frontmatter {
              title
              date
              featured_image {
                childImageSharp {
                  gatsbyImageData(
                    height: 200
                    quality: 80
                    placeholder: TRACED_SVG
                    formats: [AUTO, JPG, WEBP, AVIF]
                  )
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return allMdx.edges.map(({ node }) => node);
}
